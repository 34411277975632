.login-form-cover{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #F5F7FB;
}
.login-form-cover>div:first-child{
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.login-form-right-side{
    /* background-repeat: no-repeat !important;
    background-position: 95% 5% !important; */
    background-color: transparent !important;
}

.login-form{
    width: 950px;
    border-radius: 3px;
    overflow: hidden;
    max-width: 100%;
    /* height: 100%; */
    margin: 0 auto;
    background-color: var(--secondary-color);
}

.ant-input-suffix .anticon svg{
    color: var(--black-color) !important;
}

@media only screen and (max-width: 976px) {
    .login-form{
        width: 93%;
    }
    /* .login-form img{max-width: 400px;} */
}
/* @media only screen and (max-width: 850px) {
    .login-form .light-grey-bg .light-grey-text{
        display: none;
    }
} */

.img-logo{
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-logo .ant-image{
    width: 100%;
}