.trigger {
    padding: 0 24px;
    /* font-size: 18px; */
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .trigger:hover {
    color: #1890ff;
  }
  
  .logo {
    height: auto;
    margin: 25px 14px;
    text-align: center;   
  }
  
  .site-layout .site-layout-background {
    background: var(--white-color);
    margin: 10px;
    border-radius: 10px !important;
  }
  
/* scrollbar */
.scroll-bar::-webkit-scrollbar-track
{
	border-radius: 8px;
	background-color: #EEEEEE;
}

.scroll-bar::-webkit-scrollbar
{
	width: 7px;
	background-color: #EEEEEE;
}

.scroll-bar::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	background-color: #BBBBBB;
}







  .ant-table-body::-webkit-scrollbar,
  .ant-table::-webkit-scrollbar,
  .ant-table-container::-webkit-scrollbar,
  .ant-table-content::-webkit-scrollbar {
    width: 7px;
	background-color: #EEEEEE;
  height: 5px; 
    border-radius: 8px;
  }
  
  .ant-table-body::-webkit-scrollbar-thumb,
  .ant-table::-webkit-scrollbar-thumb,
  .ant-table-container::-webkit-scrollbar-thumb,
  .ant-table-content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #BBBBBB;
  }

  .roundedImg{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
  }

  .btnCheck .ant-space-item{
    width: 100%;
  }

  .checkAll{
    background-color:#7fb691;
    padding:10px;
    border-radius:10px;
    height:42px;
    width:100%;
    display: block;
    color: var(--black-color);
  }

  .border-0-btn{
    border: 0px !important;
    padding: 0px !important;
  }

  .listitem{
    padding: 8px;
  }

  .listitem .ant-menu-submenu-title{
    color: var(--black-color) !important;
  }

  .listitem .ant-menu-item{
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    border-radius: 10px !important;
    color: var(--black-color) !important;
    padding-left: 10px !important;
  }

  .ant-menu-dark .ant-menu-item-selected, .ant-menu-dark>.ant-menu .ant-menu-item-selected{
    background: var(--secondary-color) !important;
  }


  .listitem li .ant-menu-title-content{
    font-size: 12px;
  }


  .ant-menu-dark,.ant-menu-dark>.ant-menu{
    background: var(--brand-color) !important;
  }

  .ant-menu-inline .ant-menu-submenu-title{
    padding-left: 0px;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline{
    border-left: 2.5px solid var(--secondary-color);
    background-color: transparent;
    margin-left: 8px;
    padding-left: 8px;
  }

  .ant-menu-submenu-open .ant-menu-submenu-title{
    background-color: var(--secondary-color) !important;
  }

  .ant-menu-submenu-title{
    background-color: transparent;
    padding-left: 10px !important;
    border-radius: 8px !important;
  }